<template>
  <div class="OperatingRecord">
      <div class="OperatingInner">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="个人操作记录" name="first">
                <div class="operRecord">
                     <div class="opmain">
                        <el-table
                            :data="perRecordList"
                            border
                            style="width: 100%">
                            <el-table-column
                            prop="api_type"
                            label="接口类型"
                            width="180">
                            </el-table-column>
                            <el-table-column
                            prop="expense_num"
                            label="消费金额"
                            width="180">
                            </el-table-column>
                            <el-table-column
                            prop="expense_time"
                            label="消费时间">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="page">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage1"
                            :page-sizes="[10, 20, 30, 40, 50, 100]"
                            :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total= count1>
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="总公司操作记录" name="second" v-if="ssRecordShow">
                <div class="allRecord">
                     <div class="opUserSearch">
                        <el-row :gutter="20">
                            <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2">
                                <label for="">用户名：</label>
                            </el-col>
                            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5" class="userN">
                                 <el-input v-model="username" placeholder="请输入用户名" @input="searchName(username)"></el-input>
                                <transition name="fade">
                                    <div class="userNameList" v-show="usernameListShow">
                                        <ul>
                                        <li v-for="(item,index) in username_lists" :key="index" @click="sureUsername(item)">{{item.username}}</li>
                                        <li v-show="listNull">暂无数据~</li>
                                        </ul>
                                    </div>
                                </transition>
                            </el-col>
                             <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
                                 <el-button type="primary" plain @click="search()">查询</el-button>
                                 <el-button type="warning" plain @click="reset()">重置</el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="opmain">
                        <el-table
                            :data="allRecordList"
                            border
                            style="width: 100%">
                            <el-table-column 
                                prop="username"
                                label="姓名"
                                width="180"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="api_type"
                                label="接口类型"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                prop="expense_num"
                                label="消费金额"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                prop="expense_time"
                                label="消费时间">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="page">
                        <el-pagination
                            @size-change="handleSizeChange2"
                            @current-change="handleCurrentChange2"
                            :current-page="currentPage2"
                            :page-sizes="[10, 20, 30, 40, 50, 100]"
                            :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total= count2>
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
      </div>
  </div>
</template>

<script>
    import OperatingRecord from './OperatingRecord.js';
    export default OperatingRecord;
</script>

<style scoped>
    @import './OperatingRecord.css';
</style>